/** @format */
import ScrollReveal from "scrollreveal";
import validator from "validator";
import Splide from "@splidejs/splide";
import Notiflix from "notiflix";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

new Splide("#slider1", {
  type: "loop",
  drag: "free",
  focus: "start",
  rewind: true,
  arrows: false,
  pagination: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  lazyLoad: "nearby",
  preloadPages: -1,
  autoScroll: {
    autoStart: true,
    speed: 2,
    loop: true,
  },
  mediaQuery: "min",
  breakpoints: {
    1440: {
      destroy: true,
    },
  },
}).mount({ AutoScroll });

new Splide("#clients", {
  type: "loop",
  drag: "free",
  focus: "start",
  rewind: true,
  arrows: false,
  pagination: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  lazyLoad: "nearby",
  preloadPages: -1,
  autoScroll: {
    autoStart: true,
    speed: 2,
    loop: true,
  },
}).mount({ AutoScroll });

ScrollReveal().reveal(".hero-title", {
  delay: 400,
  cleanup: true,
  distance: "100px",
  origin: "left",
  opacity: 0,
});

ScrollReveal().reveal(".sub-title", {
  delay: 500,
  cleanup: true,
  distance: "100px",
  origin: "left",
});

ScrollReveal().reveal(".hero-image", {
  delay: 700,
  cleanup: true,
  distance: "100px",
  origin: "right",
});

ScrollReveal().reveal(".hero-description", {
  delay: 550,
  cleanup: true,
  distance: "100px",
  origin: "left",
});

ScrollReveal().reveal(".hero-btn", {
  delay: 600,
  cleanup: true,
  distance: "100px",
  origin: "left",
});

ScrollReveal().reveal(".about-wraper", {
  delay: 350,
  cleanup: true,
  distance: "300px",
});

ScrollReveal().reveal(".one", {
  delay: 500,
  cleanup: true,
  distance: "100px",
});
ScrollReveal().reveal(".two", {
  delay: 650,
  cleanup: true,
  distance: "100px",
});
ScrollReveal().reveal(".three", {
  delay: 800,
  cleanup: true,
  distance: "100px",
});

ScrollReveal().reveal(".four", {
  delay: 900,
  cleanup: true,
  distance: "100px",
});

ScrollReveal().reveal(".five", {
  delay: 1000,
  cleanup: true,
  distance: "100px",
});

ScrollReveal().reveal(".sub-list__icon", {
  delay: 300,
  cleanup: true,
  distance: "100px",
  origin: "left",
});

const links = document.querySelectorAll('a[href^="#"]');

links.forEach((link) => {
  link.addEventListener("click", function (e) {
    e.preventDefault();

    const targetId = this.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 100,
        behavior: "smooth",
      });
    }
  });
});

const buttons = document.querySelectorAll(".about-btn");

buttons.forEach((button) => {
  button.addEventListener("click", () => {
    const wrapper = button.closest(".about-wraper");

    const box = wrapper.querySelector(".about-box");
    const descriptionBox = wrapper.querySelector(".about__description-box");

    const openedBoxHeight = descriptionBox.scrollHeight;

    const nextElement = wrapper.nextElementSibling;
    if (nextElement) {
      nextElement.style.marginTop = `${openedBoxHeight + 48}px`;
    }

    box.classList.toggle("box-open");
    descriptionBox.classList.toggle("box-open");
    button.classList.toggle("box-open");

    if (!descriptionBox.classList.contains("box-open") && nextElement) {
      nextElement.style.marginTop = 0;
    }
  });
});

const mobileBtn = document.querySelector(".mobile-btn");
const btnOpen = document.querySelector(".mobile-open");
const btnClose = document.querySelector(".mobile-close");
const menu = document.querySelector(".mobile-menu");

btnOpen.addEventListener("click", function () {
  if (!menu.classList.contains("is-open")) {
    menu.classList.add("is-open");
    btnOpen.classList.add("--hidden");
    btnClose.classList.add("--active");
    mobileBtn.classList.add("--hidden");
  }
});

btnClose.addEventListener("click", function () {
  if (menu.classList.contains("is-open")) {
    menu.classList.remove("is-open");
    btnOpen.classList.remove("--hidden");
    btnClose.classList.remove("--active");
    mobileBtn.classList.remove("--hidden");
  }
});

const iconBtn = document.querySelector(".icon-mobile");

const transformBtn = () => {
  iconBtn.classList.toggle("icon-click");

  setTimeout(() => {
    iconBtn.classList.toggle("icon-click");
  }, 250);
};

iconBtn.addEventListener("click", transformBtn);

const form = document.getElementById("my-form");

async function handleSubmit(evt) {
  evt.preventDefault();

  const data = new FormData(evt.target);
  fetch(evt.target.action, {
    method: form.method,
    body: data,
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        Notiflix.Notify.success("Ваше повідомлення відправлено");
        form.reset();
        toggleModal();
      } else {
        response.json().then((data) => {
          if (Object.hasOwn(data, "errors")) {
            Notiflix.Notify.failure("Щось пішло не так. Спробуйте пізніше")
              .map((error) => error["message"])
              .join(", ");
          } else {
            Notiflix.Notify.failure("Щось пішло не так. Спробуйте пізніше");
          }
        });
      }
    })
    .catch((error) => {
      Notiflix.Notify.failure("Щось пішло не так. Спробуйте пізніше");
    });
}

form.addEventListener("submit", handleSubmit);

const openModal = document.querySelector(".hero-btn");
const closeModal = document.querySelector(".modal-close");
const backdrop = document.querySelector(".backdrop");
const modal = document.querySelector(".modal");

function toggleModal() {
  const page = document.body;
  page.classList.toggle("modal-open");
  backdrop.classList.toggle("is-hidden");
  mobileBtn.classList.toggle("--hidden");
}

function closeModalOnEscape(e) {
  if (e.key === "Escape") {
    toggleModal();
  }
}

function closeModalOnBackdrop(e) {
  if (
    (e.target === backdrop || e.target === modal) &&
    !modal.contains(e.target)
  ) {
    toggleModal();
  }
}

openModal.addEventListener("click", toggleModal);
closeModal.addEventListener("click", toggleModal);
backdrop.addEventListener("click", closeModalOnBackdrop);
window.addEventListener("keydown", closeModalOnEscape);

const inputNumber = document.querySelector(".input-number");

function validatePhoneNumber(e) {
  const { value } = e.currentTarget;

  const phoneRegex = /^(\+380\d{9}|0\d{9})$/;

  if (!phoneRegex.test(value)) {
    Notiflix.Notify.failure("Введіть правильний номер телефону");
    e.currentTarget.value = "";
  }
}

inputNumber.addEventListener("change", validatePhoneNumber);
